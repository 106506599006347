<template>
  <div class="contract-cont">
    <div class="group-name">
      <div class="group-line">
        <div class="group-line-in"></div>
      </div>
      <div class="group-title">您所查询的合同信息如下</div>
    </div>

    <div class="list">
      <ul class="box shadow" v-for="(item, index) in list" :key="index">
        <li class="item">
          <div class="label">服务名称</div>
          <div class="text">{{item.productName}}</div>
        </li>
        <li class="item">
          <div class="label">合同号</div>
          <div class="text">
            <div class="text-in nowrap">{{item.id}}</div>
          </div>
        </li>
        <li class="item">
          <div class="label">购机时间</div>
          <div class="text">{{item.datecosttime}}</div>
        </li>
        <li class="item">
          <div class="label">生效时间</div>
          <div class="text">{{item.dateeffectivedate}}</div>
        </li>
        <li class="item">
          <div class="label">当前IMEI</div>
          <div class="text">{{item.strimeinum}}</div>
        </li>
        <li class="item">
          <div class="label">操作</div>
          <div class="text">
            <cube-button class="btn" :class="{'btn-un': item.strimeinum != 'defaultImeiCode'}" @click="goPath(item)">立即申请</cube-button>
          </div>
        </li>
      </ul>
    </div>
    <div class="p-title">
      <p>IMEI查询方式：</p>
      <p>A"设置"菜单-“通用”-“关于手机”查看</p>
      <p>B购买设备的外盒查看</p>
      <p>C手机拨号键盘输入“*#06#”</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScarchImei',
  data () {
    return {
      form: this.$route.query,
      list: [],
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    /**
     * 获取数据
     */
    getData () {
      const vm = this;
      let postData = {...this.form}
      if(! this.form.strcontactnum){
        this.$createToast({
          txt: '无手机号',
          type: 'warn'
        }).show()
        return
      }else if(! this.form.strcardid){
        this.$createToast({
          txt: '无证件号',
          type: 'warn'
        }).show()
        return
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/imei/showImei", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(response.data.body.list && response.data.body.list.length > 0){
              vm.list = response.data.body.list;
            }else{
              vm.$createToast({
                txt: '未查询到服务信息',
                type: 'warn'
              }).show()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 跳转页面
     */
    goPath (obj) {
      if(obj.strimeinum != 'defaultImeiCode'){
        this.$createToast({
          txt: '非默认IMEI，不可修改',
          type: 'warn'
        }).show()
        return
      }
      this.$router.push({
        path: '/imei/modify/' + obj.id,
        query: {
          productName: obj.productName,
          strcontactnum: this.form.strcontactnum,
          strcardid: this.form.strcardid,
          imei: obj.strimeinum
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  font-size: 30px;
  border-radius: 14px;
  .item{
    display: flex;
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
      .text{
        color: #208170;
        font-weight: 700;
      }
    }
    padding: 21px 0;
    line-height: 48px;
    .label{
      padding-right: 20px;
      width: 160px;
    }
    .text{
      flex: 10;
      text-align: right;
      .text-in{
        float: right;
        width: 420px;
      }
    }
  }
}
.btn{
  display: inline-block;
  font-size: 26px;
  width: 148px;
  border-radius: 30px;
  text-align: center;
  padding: 0;
  line-height: 48px;
  background-color: #208170;
}
.btn-un{
  background-color: #999;
}
.p-title{
  font-size: 26px;
  color: #999999;
  line-height: 37px;
  padding: 0 41px;
}
</style>